import { useContext, useState } from 'react';

// modules
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { CircleFlag } from 'react-circle-flags';

// contexts
import { AppContext } from '../../contexts/AppContext';
import { PlayerContext } from '../../contexts/PlayerContext';

// interfaces
import { Language } from '../../interfaces/language/language.interface';

// mui
import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography
} from '@mui/material';

// lang data
import { getFlagCode, getAllLanguageDescriptionsFromAudios } from '../../translations/languages';

// custom hooks
import { useCurrentPath } from '../../hooks/useCurrentPath';

// flags
import "../styles/country-flags.css";


export const LangSelector = () => {
    const navigate = useNavigate();
    const [path] = useCurrentPath();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { appLang, appRecording } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentLanguage } = lang;

    const { setRecording } = appRecording;

    const playerContext = useContext(PlayerContext);
    const { data, type: playerType } = playerContext;
    const audioQueue = data?.audioQueue || [];

    let nonVoidLanguages: Language[] = getAllLanguageDescriptionsFromAudios(audioQueue);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handleLangChange = (event: React.MouseEvent<HTMLLIElement>, index: number) => {
        setAnchorEl(null);
        const oldLang = `/${currentLanguage}/`;
        const newLang = `/${nonVoidLanguages[index].code}/`;

        if (oldLang !== newLang) {
            setRecording(false);
            const newLangPath = path.replace(oldLang, newLang);
            navigate(newLangPath);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const tooltipPlacement = playerType.mode === 'player' ? 'right' : 'top';

    return (
        <div>
            <Tooltip
                placement={tooltipPlacement}
                title={<FormattedMessage id='app.player-language-button' />}
            >
                <IconButton
                    aria-label='change language'
                    color="inherit"
                    onClick={handleClick}
                >
                    <CircleFlag
                        id="flag-img-language-selection"
                        countryCode={getFlagCode(currentLanguage)}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {
                    nonVoidLanguages.map((lang, index) => (
                        <MenuItem
                            key={lang.description}
                            onClick={event => handleLangChange(event, index)}
                        >
                            <Grid container>
                                <Grid
                                    item xs={10}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: "row"
                                    }}
                                >
                                    <CircleFlag id="flag-img-language" countryCode={getFlagCode(lang.code)} />
                                    <Typography sx={{ marginLeft: "7px" }} >
                                        {lang.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    ))
                }
            </Menu>
        </div >
    );
}