// interfaces
import { Language } from "../interfaces/language/language.interface";
import { PlayerAudio } from "../interfaces/player/player.interface";


export const languages = [
    "en-US",
    "es-ES",
    "fr-FR",
    "en-IN",
    "hi-IN",
    "nl-NL"
]

export const languageDescriptions: Language[] = [
    {
        code: "en-US",
        description: "English"
    },
    {
        code: "es-ES",
        description: "Español"
    },
    {
        code: "fr-FR",
        description: "Français"
    },
    {
        code: "en-IN",
        description: "English"
    },
    {
        code: "hi-IN",
        description: "Hindi"
    },
    {
        code: "nl-NL",
        description: "Netherlands"
    },
]

export const getFlagCode = (code: string) => {
    if (typeof code !== 'undefined') {
        if (code === "en-US") return "us";
        if (code === "en-GB") return "gb";
        if (code === "en-IN") return "in";
        if (code === "hi-IN") return "in";
        if (code === "nl-NL") return "nl";
        else return code.substring(0, 2);
    } else {
        return "undefined";
    }
}

export const getAllLanguageDescriptionsFromAudios = (audioQueue: PlayerAudio[]): Language[] => {

    let availableLanguages: Language[] = [];

    for (const audio of audioQueue){

        let articleLanguageFound: boolean = false;
        let i: number = 0;
        while (!articleLanguageFound && i < languageDescriptions.length){

            if (audio.language === languageDescriptions[i].code){
                if (!availableLanguages.includes(languageDescriptions[i])){
                    availableLanguages.push(languageDescriptions[i]);
                }
                articleLanguageFound = true;
            }
            i++;
        }

        while (!articleLanguageFound && i < languageDescriptions.length){
            if (audio.language.substring(0, 2) === languageDescriptions[i].code.substring(0, 2)){
                if (!availableLanguages.includes(languageDescriptions[i])){
                    availableLanguages.push(languageDescriptions[i]);
                }
                articleLanguageFound = true;
            }
            i++;
        }
    }

    return availableLanguages;
}
